import React from 'react';
import { Editor } from '@tinymce/tinymce-react';

// TODO: we should keep apiKeys in environment variables
const apiKey = 'zuz0w4ka9cm7k0lwt4ren9gsnl7bkchz7peuzmdtp781sau1';

function TextEditor({
  initialValue,
  props,
}) {
  return (
    <Editor
      apiKey={apiKey}
      value={initialValue || ''}
      init={{
        height: '100%',
        menubar: true,
        plugins: [
          'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
          'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
          'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount',
        ],
        toolbar: 'undo redo | blocks | '
            + 'bold italic forecolor | alignleft aligncenter '
            + 'alignright alignjustify | bullist numlist outdent indent | '
            + 'removeformat | help',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
      }}
      onEditorChange={(e) => {
        props.setFieldValue('content', e);
      }}
    />
  );
}

export default TextEditor;
