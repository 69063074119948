/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getBlog = /* GraphQL */ `
  query GetBlog($id: ID!, $nextToken: String, $limit: Int) {
    getBlog(id: $id) {
      id
      name
      posts(nextToken: $nextToken, limit: $limit) {
        items {
          id
          title
          content
          visibility
          publishAt
          featuredImage
          featuredImageAlt
          exerpt
          author
          avatar
          categories
          tags
          seoTitle
          seoDescription
          handle
          duration
          media
          createdAt
          updatedAt
          blogPostsId
        }
        nextToken
      }
      image
      createdAt
      updatedAt
    }
  }
`;
export const listBlogs = /* GraphQL */ `
  query ListBlogs(
    $filter: ModelBlogFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBlogs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        posts {
          nextToken
        }
        image
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPost = /* GraphQL */ `
  query GetPost($id: ID!) {
    getPost(id: $id) {
      id
      title
      blog {
        id
        name
        posts {
          nextToken
        }
        image
        createdAt
        updatedAt
      }
      comments {
        items {
          id
          content
          createdAt
          updatedAt
          postCommentsId
        }
        nextToken
      }
      content
      visibility
      publishAt
      featuredImage
      featuredImageAlt
      exerpt
      author
      avatar
      categories
      tags
      seoTitle
      seoDescription
      handle
      duration
      media
      faq {
        id
        question
        answer
      }
      createdAt
      updatedAt
      blogPostsId
    }
  }
`;
export const listPosts = /* GraphQL */ `
  query ListPosts(
    $filter: ModelPostFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        blog {
          id
          name
          image
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        content
        visibility
        publishAt
        featuredImage
        featuredImageAlt
        exerpt
        author
        avatar
        categories
        tags
        seoTitle
        seoDescription
        handle
        duration
        media
        faq {
          id
          question
          answer
        }
        createdAt
        updatedAt
        blogPostsId
      }
      nextToken
    }
  }
`;
export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      post {
        id
        title
        blog {
          id
          name
          image
          createdAt
          updatedAt
        }
        comments {
          nextToken
        }
        content
        visibility
        publishAt
        featuredImage
        featuredImageAlt
        exerpt
        author
        avatar
        categories
        tags
        seoTitle
        seoDescription
        handle
        duration
        media
        faq {
          id
          question
          answer
        }
        createdAt
        updatedAt
        blogPostsId
      }
      content
      createdAt
      updatedAt
      postCommentsId
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        post {
          id
          title
          content
          visibility
          publishAt
          featuredImage
          featuredImageAlt
          exerpt
          author
          avatar
          categories
          tags
          seoTitle
          seoDescription
          handle
          duration
          media
          createdAt
          updatedAt
          blogPostsId
        }
        content
        createdAt
        updatedAt
        postCommentsId
      }
      nextToken
    }
  }
`;
