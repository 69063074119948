import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import styled from '@emotion/styled';
import {
  Button, Heading, Text, Badge,
} from '@aws-amplify/ui-react';
import { getBlog } from '../graphql/queries';
import { createPost as createPostMutation } from '../graphql/mutations';
import PopupCreatePost from '../components/PopupCreatePost';
import { getImageUrl, dateToUTC } from '../utils';

const Container = styled('div')`
  max-width: 1200px;
  width: 100%;
  margin: 30px auto;
`;
const TableHeader = styled('div')`
  display: grid;
  grid-template-columns: 100px 1fr 150px;
  align-items: center;
  justify-items: center;
`;
const PostsConatiner = styled('div')`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 20px;
`;
const PostConatiner = styled('div')`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 5px;
  ${'' /* height: 100px; */}
  img {
    max-width: 100px;
  }
`;
const DivFlexLeft = styled('div')`
  width: 100px;
  padding: 5px;
  display: grid;
  place-content: center;
`;
const DivFlexRight = styled('div')`
  width: 200px;
  padding: 5px;
  display: grid;
  place-content: center;
  gap: 20%;
`;
const DivFlexCenter = styled('div')`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 20px;
`;

const initialFormState = {
  title: '',
  content: '',
  visibility: true,
  publishAt: new Date(new Date().getTime() + 24 * 60 * 60 * 1000), // next day
  featuredImage: undefined,
  featuredImageAlt: '',
  author: '',
  avatar: '',
  exerpt: '',
  seoTitle: '',
  seoDescription: '',
  handle: '',
  duration: 0,
  media: [],
  faq: [],
  tags: [],
};

function Posts() {
  const [blog, setBlog] = useState(null);
  const [posts, setPosts] = useState([]);
  const { id: blogId } = useParams();
  const [onPopupTitle, setOnPopupTitle] = useState(false);
  const navigate = useNavigate();
  // async function fetchPosts() {
  //   const apiData = await API.graphql({
  //     query: listPosts,
  //     variables: { filter: { blogPostsId: { eq: blogId } } },
  //   });

  //   const postsFromAPI = apiData.data.listPosts.items;
  //   await Promise.all(postsFromAPI.map(async (post) => {
  //     if (post.featuredImage) {
  //       const image = getImageUrl(post.featuredImage);
  //       post.featuredImageUrl = image;
  //     }
  //     if (post.media.length > 0) {
  //       const images = post.media.map(getImageUrl);
  //       post.mediaUrls = images;
  //     }
  //     return post;
  //   }));
  //   setPosts(postsFromAPI.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt)));
  // }
  useEffect(() => {
    async function fetchBlog() {
      let allPosts = [];

      const fetchPosts = async (nextToken) => {
        const { data } = await API.graphql({
          query: getBlog,
          variables: {
            id: blogId,
            limit: 1000,
            nextToken,
          },
        });

        const { items, nextToken: newNextToken } = data.getBlog.posts;
        allPosts = allPosts.concat(items);

        if (newNextToken) {
          await fetchPosts(newNextToken); // Рекурсивный вызов для получения следующей страницы
        }
      };
      await fetchPosts(null);

      setBlog(allPosts);
      const postsFromAPI = allPosts;
      await Promise.all(
        postsFromAPI.map(async (post) => {
          if (post.featuredImage) {
            const image = getImageUrl(post.featuredImage);
            post.featuredImageUrl = image;
          }
          if (post.media.length > 0) {
            const images = post.media.map(getImageUrl);
            post.mediaUrls = images;
          }
          return post;
        }),
      );
      setPosts(
        postsFromAPI.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
        ),
      );
    }
    if (blogId) {
      fetchBlog();
      // fetchPosts();
    }
  }, [blogId]);
  async function createPostTitle(title) {
    if (!title) return;
    const { data } = await API.graphql({
      query: createPostMutation,
      variables: {
        input: {
          ...initialFormState,
          title,
          blogPostsId: blogId,
          visibility: true,
          publishAt: initialFormState.publishAt,
        },
      },
    });
    const newPost = data?.createPost;
    navigate(`/blog/post/${newPost.id}`);
  }

  return (
    <Container>
      <PostsConatiner>
        <TableHeader>
          <Link to="/" className="amplify-button amplify-button--link">
            Home
          </Link>
          <Heading level={2}>{blog ? blog.name : 'Posts'}</Heading>
          <Button variation="primary" onClick={() => setOnPopupTitle(true)}>
            Add post
          </Button>
        </TableHeader>
        <PopupCreatePost
          onPopup={onPopupTitle}
          handleClosePopup={() => {
            setOnPopupTitle(false);
          }}
          onCreate={createPostTitle}
        />
        {posts.length === 0 ? <h2>Loading...</h2> : posts.map((post) => (
          <PostConatiner key={post.id}>
            <DivFlexLeft>
              {post.featuredImageUrl && (
                <img
                  src={post.featuredImageUrl}
                  style={{ width: 400 }}
                  alt={post.title}
                />
              )}
            </DivFlexLeft>
            <DivFlexCenter>
              <Heading level={5}>
                {post.title}
                <span>{!post.visibility && <Badge>Hidden</Badge>}</span>
              </Heading>
              <Text>
                <span>
                  <b>Author:</b>
                  &nbsp;
                </span>
                {post.author}
              </Text>
              <Text>
                <span>
                  <b>Create at:</b>
                  &nbsp;
                </span>
                {dateToUTC(post.createdAt)}
              </Text>
            </DivFlexCenter>
            <DivFlexRight>
              <Link
                to={`/blog/post/${post.id}`}
                className="amplify-button amplify-button--link"
              >
                Edit post
              </Link>
              <Link
                to={`/post/${post.id}`}
                target="_blank"
                className="amplify-button amplify-button--link"
              >
                Preview
              </Link>
            </DivFlexRight>
          </PostConatiner>
        ))}
      </PostsConatiner>
    </Container>
  );
}

export default Posts;
