import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { API } from 'aws-amplify';
import styled from '@emotion/styled';
import { getPost } from '../graphql/queries';
import { getImageUrl } from '../utils';

const Container = styled('div')`
  max-width:1200px;
  width:100%;
  margin: 30px auto;
  display:flex;
  flex-direction: column;
  & >button {
    margin-left:auto;
  }
  img {     
    margin: auto;
  }
`;
function Post() {
  const [postData, setPostData] = useState(null);
  const [imageURL, setImageURL] = useState(null);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  async function onGetPost() {
    const apiData = await API.graphql({ query: getPost, variables: { id } });
    if (apiData.errors) {
      setLoading(false);
      return;
    }
    const postFromAPI = apiData.data.getPost;
    if (postFromAPI.featuredImage) {
      setImageURL(getImageUrl(postFromAPI.featuredImage));
    }
    setPostData(apiData.data.getPost);
    setLoading(false);
  }
  useEffect(() => {
    setLoading(true);
    onGetPost();
  }, []);

  return loading
    ? (<Container>loading...</Container>)
    : (
      <Container>
        { imageURL && <img src={imageURL} alt={postData.title} /> }
        <div><h1>{postData?.title}</h1></div>
        <div dangerouslySetInnerHTML={{ __html: postData?.content }} />
        <div>
          <span>{postData?.publishAt}</span>
          <span>{postData?.author}</span>
        </div>
      </Container>
    );
}

export default Post;
