import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { API, Storage } from 'aws-amplify';
import { Button, TextField, Heading } from '@aws-amplify/ui-react';
import styled from '@emotion/styled';
import { listBlogs } from '../graphql/queries';
import {
  createBlog as createBlogMutation,
  deleteBlog as deleteBlogMutation,
} from '../graphql/mutations';
import PopupWarning from '../components/PopupWarning';
import { getImageUrl } from '../utils';

/// CSS
const Container = styled('div')`
  max-width: 1200px;
  width: 100%;
  margin: 30px auto;
  text-align: center;
`;
const CreateBlogContainer = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 500px;
  align-items: center;
  justify-content: center;
  margin: 50px auto;
  padding: 20px;
  text-align: center;
`;
const BlogsContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-bottom: 100px;
  margin-top: 20px;
`;
const BlogContainer = styled('div')`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
  gap: 20px;
  align-items: center;
  justify-content: space-between;
  height: -webkit-fill-available;
  background-color: #bfd7e9;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  a {
    text-decoration: none;
  }
  img {
    width: 100%;
    max-width: 400px;
    height: 200px;
    object-fit: cover;
  }
`;
const ContextMenu = styled.div`
  position: absolute;
  top: 15px;
  right: 35px;
  max-width: 200px;
  height: 100px;
  padding: 10px;
  box-sizing: border-box;
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
const ContextMenuButton = styled.button`
  position: absolute;
  top: 5px;
  right: 5px;
  background: transparent;
  border: none;
  cursor: pointer;
  color:red;
`;
/// JS
const initialFormState = { name: '' };

function Blogs({ signOut }) {
  const [blogs, setBlogs] = useState([]);
  const [showAddBlogBlock, setShowAddBlogBlock] = useState(false);
  const [formData, setFormData] = useState(initialFormState);

  async function fetchBlogs() {
    const apiData = await API.graphql({ query: listBlogs });
    const blogsFromAPI = apiData.data.listBlogs.items;
    await Promise.all(
      blogsFromAPI.map(async (blog) => {
        if (blog.image) {
          const image = getImageUrl(blog.image);
          blog.imageUrl = image;
        }
        return blog;
      }),
    );
    setBlogs(apiData.data.listBlogs.items);
  }
  useEffect(() => {
    fetchBlogs();
  }, []);

  async function onChange(e) {
    if (!e.target.files[0]) return;
    const file = e.target.files[0];
    setFormData({ ...formData, image: file.name });
    await Storage.put(file.name, file);
    fetchBlogs();
  }

  async function createBlog() {
    if (!formData.name) return;
    const { data } = await API.graphql({
      query: createBlogMutation,
      variables: { input: formData },
    });
    const newBlog = data?.createBlog;
    if (newBlog?.image) {
      const image = getImageUrl(newBlog.image);
      newBlog.imageUrl = image;
    }
    setBlogs([...blogs, newBlog]);
    setFormData(initialFormState);
  }
  const [onPopup, setOnPopup] = useState('');
  const handleClosePopup = () => {
    setOnPopup('');
  };

  async function deleteBlog(id = onPopup) {
    if (id) {
      const { data } = await API.graphql({
        query: deleteBlogMutation,
        variables: { input: { id } },
      });
      if (data?.deleteBlog?.id) {
        const newBlogArray = blogs.filter(
          (blog) => blog.id !== data.deleteBlog.id,
        );
        setBlogs(newBlogArray);
      }
    }
  }

  const [isContextMenuOpen, setIsContextMenuOpen] = useState(null);

  const toggleContextMenu = (id) => {
    if (id === isContextMenuOpen) {
      return setIsContextMenuOpen(null);
    }
    setIsContextMenuOpen(id);
    return true;
  };
  return (
    <Container>
      <Heading level={1}>Blogs CMS</Heading>
      {showAddBlogBlock && (
        <CreateBlogContainer>
          <TextField
            onChange={(e) => setFormData({ ...formData, name: e.target.value })}
            placeholder="Blog name"
            value={formData.name}
            isRequired
          />
          <div>
            <p>Main blog image</p>
            <input type="file" onChange={onChange} />
          </div>

          <Button onClick={createBlog} variation="primary">
            Create Blog
          </Button>
          <Button
            onClick={() => {
              setShowAddBlogBlock(false);
            }}
            size="small"
            color="red"
            style={{
              position: 'absolute',
              top: '0',
              right: '0',
            }}
          >
            X
          </Button>
        </CreateBlogContainer>
      )}
      <Button
        onClick={() => {
          setShowAddBlogBlock(true);
        }}
        size="small"
        style={{
          position: 'absolute',
          top: '50px',
          left: '50px',
        }}
      >
        Add blog
      </Button>
      <Button
        onClick={signOut}
        style={{
          position: 'absolute',
          top: '50px',
          right: '50px',
        }}
      >
        Sign Out
      </Button>

      <BlogsContainer>
        {blogs.map((blog) => (
          <BlogContainer
            key={blog.id || blog.name}
            style={
              blog.imageUrl
                ? { backgroundImage: `url(${blog.imageUrl})` }
                : undefined
            }
          >
            <Link to={`/blog/${blog.id}`}>
              <Heading level={2}>{blog.name}</Heading>
            </Link>
            <ContextMenuButton onClick={() => toggleContextMenu(blog.id)}>
              ***
            </ContextMenuButton>
            {!!isContextMenuOpen && isContextMenuOpen === blog.id && (
              <ContextMenu>
                <Button
                  onClick={() => {
                    setOnPopup(blog.id);
                  }}
                  size="small"
                  color="red"
                >
                  Delete blog
                </Button>
              </ContextMenu>
            )}
          </BlogContainer>
        ))}
        <PopupWarning
          onPopup={onPopup}
          handleClosePopup={handleClosePopup}
          onDelete={deleteBlog}
        />
      </BlogsContainer>
    </Container>
  );
}

export default Blogs;
