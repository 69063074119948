import React from 'react';
import './App.css';
import { withAuthenticator } from '@aws-amplify/ui-react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Blogs from './pages/Blogs';
import Posts from './pages/Posts';
import Post from './pages/Post';
import ChangePost from './pages/ChangePost';

function App({ signOut }) {
  return (

    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Blogs signOut={signOut} />} />
          <Route path="/blog/:id" element={<Posts />} />
          <Route path="/post/:id" element={<Post />} />
          <Route path="/blog/post/:id" element={<ChangePost />} />
        </Routes>
      </div>
    </Router>

  );
}

export default withAuthenticator(App);
