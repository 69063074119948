import AWSExports from './aws-exports';

// eslint-disable-next-line import/prefer-default-export
export const getImageUrl = (objectName) => encodeURI(`https://${AWSExports.aws_user_files_s3_bucket}.s3.amazonaws.com/public/${objectName}`);

const MAX_IMAGE_SIZE = 5000000; // in bytes
const MAX_IMAGE_DIMENSION = 2048;

const getImageDimensions = (file) => {
  const img = document.createElement('img');
  const promise = new Promise((resolve, reject) => {
    img.onload = () => {
      // Natural size is the actual image size regardless of rendering.
      // The 'normal' `width`/`height` are for the **rendered** size.
      const width = img.naturalWidth;
      const height = img.naturalHeight;

      // Resolve promise with the width and height
      resolve({ width, height });
    };

    // Reject promise on error
    img.onerror = reject;
  });

  // Setting the source makes it start downloading and eventually call `onload`
  const url = URL.createObjectURL(file);
  img.src = url;
  return promise;
};

export const checkImageSize = async (file) => {
  const fileSize = file.size;
  if (fileSize > MAX_IMAGE_SIZE) {
    return 'file size is too large';
  }
  const imgDimensions = await getImageDimensions(file);
  if (imgDimensions.width >= MAX_IMAGE_DIMENSION) {
    return `Image width exceeds ${MAX_IMAGE_DIMENSION}px`;
  }
  if (imgDimensions.height >= MAX_IMAGE_DIMENSION) {
    return `Image height exceeds ${MAX_IMAGE_DIMENSION}px`;
  }
  return null;
};

export const dateToUTC = (date) => new Date(date).toUTCString();
