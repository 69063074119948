import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { API, Storage } from 'aws-amplify';
import styled from '@emotion/styled';
import { Button, Heading } from '@aws-amplify/ui-react';

import {
  deletePost as deletePostMutation,
  updatePost as updatePostMutation,
} from '../graphql/mutations';
import { getPost } from '../graphql/queries';
import PostForm from '../components/PostForm';
import PopupWarning from '../components/PopupWarning';
import { getImageUrl, checkImageSize } from '../utils';

const Container = styled('div')`
  max-width: 1200px;
  width: 100%;
  margin: 30px auto;
  display: flex;
  flex-direction: column;
  & > button {
    margin-left: auto;
  }
  h2 {
    text-align: center;
  }
`;

const ErrorMessage = styled('div')`
  position: fixed;
  bottom: 20px;
  right: 20px;
  padding: 20px;
  border-radius: 20px;
  color: red;
  background: #fff;
  border: 1px solid red;
`;

function ChangePost() {
  const [post, setPost] = useState();
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();
  const [onPopup, setOnPopup] = useState('');
  const handleClosePopup = () => {
    setOnPopup('');
  };
  const [imagesToDelete, setImagesToDelete] = React.useState([]);
  const [errorMessage, setErrorMessage] = React.useState(null);

  async function onGetPost() {
    const apiData = await API.graphql({ query: getPost, variables: { id } });
    if (apiData.errors) {
      setLoading(false);
      return;
    }
    let featuredImageUrl;
    let mediaUrls;
    if (apiData.data?.getPost?.featuredImage) {
      featuredImageUrl = getImageUrl(apiData.data?.getPost?.featuredImage);
    }
    if (apiData.data?.getPost?.media.length > 0) {
      mediaUrls = apiData.data?.getPost?.media.map(getImageUrl);
    }
    setPost({ ...apiData.data.getPost, featuredImageUrl, mediaUrls });
    setLoading(false);
  }
  useEffect(() => {
    setLoading(true);
    onGetPost();
  }, []);

  useEffect(() => {
    if (errorMessage) {
      setTimeout(() => {
        setErrorMessage(null);
      }, 3000);
    }
  }, [errorMessage]);

  async function onFeaturedImageChange(e) {
    if (!e.target.files[0]) return;
    const file = e.target.files[0];
    const error = await checkImageSize(file);
    if (error) {
      setErrorMessage(error);
      return;
    }
    const fileName = `${post.id}-${file.name}`;
    setImagesToDelete([...imagesToDelete, post.featuredImage]);
    if (post) {
      setPost({
        ...post,
        featuredImage: fileName,
        featuredImageUrl: getImageUrl(file.name),
      });
    }
    await Storage.put(fileName, file);
  }

  async function onImageChange(e) {
    if (!e.target.files[0]) return;
    const { files } = e.target;
    const filesArr = [...files];
    const errors = await (
      await Promise.all(filesArr.map(checkImageSize))
    ).filter((err) => !!err);
    if (errors.length > 0) {
      setErrorMessage(errors[0]);
      return;
    }
    if (post) {
      const fileNames = filesArr.map((file) => `${post.id}-${file.name}`);
      const postMedia = fileNames.reduce((media, fName) => {
        if (!media.includes(fName)) {
          media.push(fName);
        }
        return media;
      }, post.media);
      const postMediaUrls = postMedia.map(getImageUrl);
      await Promise.all(
        filesArr.map(async (file) => {
          await Storage.put(`${post.id}-${file.name}`, file);
        }),
      );
      setPost({ ...post, media: postMedia, mediaUrls: postMediaUrls });
    }
  }

  function deleteImage(fileName) {
    const filteredMedia = post.media.filter((name) => name !== fileName);
    setImagesToDelete([...imagesToDelete, fileName]);
    setPost({
      ...post,
      media: filteredMedia,
    });
  }

  async function updateImagesInStorage(submit) {
    if (submit && imagesToDelete.length > 0) {
      await Promise.all(
        imagesToDelete.map(async (fileName) => {
          await Storage.remove(fileName);
        }),
      );
      setImagesToDelete([]);
    }
  }

  async function deletePost() {
    const { data } = await API.graphql({
      query: deletePostMutation,
      variables: { input: { id } },
    });
    if (data?.deletePost?.featuredImage) {
      await Storage.remove(data?.deletePost?.featuredImage);
    }
    if (data?.deletePost?.media?.length > 0) {
      data?.deletePost?.media.forEach((key) => Storage.remove(key));
    }
    navigate(`/blog/${post?.blogPostsId}`);
  }
  async function updatePost(values) {
    const isUpdatePost = {
      title: values.title,
      content: values.content,
      visibility: values.visibility,
      publishAt: values.publishAt,
      featuredImage: post.featuredImage,
      featuredImageAlt: post.featuredImageAlt,
      author: values.author,
      avatar: values.avatar,
      exerpt: values.exerpt,
      categories: values.categories,
      seoTitle: values.seoTitle,
      seoDescription: values.seoDescription,
      handle: values.handle,
      duration: values.duration,
      media: post.media,
      faq: values.faq,
      tags: values.tags,
    };
    if (!values.title && !values.content) return;
    await API.graphql({
      query: updatePostMutation,
      variables: { input: { id, ...isUpdatePost } },
    });
    await updateImagesInStorage();
    navigate(`/blog/${post?.blogPostsId}`);
  }
  return loading ? (
    <div>loading...</div>
  ) : (
    <Container>
      <Link
        to={`/blog/${post?.blogPostsId}`}
        style={{
          position: 'absolute',
          top: '50px',
        }}
        className="amplify-button amplify-button--link"
      >
        Back
      </Link>
      <Heading level={2}>Edit post</Heading>
      <Button size="small" color="red" onClick={() => setOnPopup(id)}>
        Delete post
      </Button>
      {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      {post && (
        <PostForm
          onSubmit={updatePost}
          onFeaturedImageChange={onFeaturedImageChange}
          id={id}
          initialValues={post}
          onImageChange={onImageChange}
          onImageDelete={deleteImage}
        />
      )}

      <PopupWarning
        onPopup={onPopup}
        handleClosePopup={handleClosePopup}
        onDelete={deletePost}
      />
    </Container>
  );
}

export default ChangePost;
